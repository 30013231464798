import { render, staticRenderFns } from "./SpendAnalysis.vue?vue&type=template&id=3498d200&scoped=true&"
import script from "./SpendAnalysis.vue?vue&type=script&lang=js&"
export * from "./SpendAnalysis.vue?vue&type=script&lang=js&"
import style0 from "./SpendAnalysis.vue?vue&type=style&index=0&id=3498d200&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3498d200",
  null
  
)

export default component.exports