<template>
  <div class="features__wrapper">
    <div class="feature" @click="$router.push('/dashboard/transfer')">
      <img
        class="feature__img"
        src="../../assets/images/send-money-icon.svg"
        alt="send-money-icon"
      />
      <span class="feature__text">Send Money</span>
    </div>
    <!-- <div class="feature pending" @click="$router.push('')">
      <img
        class="feature__img"
        src="../../assets/images/products-icon.svg"
        alt="products-icon"
      />
      <span class="feature__text">Products</span>
    </div> -->
    <div class="feature" @click="$router.push('/dashboard/airtime-and-data')">
      <img
        class="feature__img"
        src="../../assets/images/airtime-data-icon.svg"
        alt="airtime-data-icon"
      />
      <span class="feature__text">Airtime & Data</span>
    </div>
    <div
      class="feature"
      @click="$router.push('/dashboard/bills/bills-payment')"
    >
      <img
        class="feature__img"
        src="../../assets/images/bills-payment-icon.svg"
        alt="bills-payment-icon"
      />
      <span class="feature__text">Pay Bills</span>
    </div>
    <!-- <div class="feature pending" @click="$router.push('')">
      <img
        class="feature__img"
        src="../../assets/images/investments-icon.svg"
        alt="investments-icon"
      />
      <span class="feature__text">Invest & Loan</span>
    </div> -->
    <div
      class="feature"
      @click="$router.push('/dashboard/cards/cards-management')"
    >
      <img
        class="feature__img"
        src="../../assets/images/card-mgt-icon.svg"
        alt="card-mgt-icon"
      />
      <span class="feature__text">Card Mgt</span>
    </div>
    <!-- <div class="feature" @click="$router.push('/account-opening')">
      <img
        class="feature__img"
        src="../../assets/images/accounts-icon.svg"
        alt="accounts-icon"
      />
      <span class="feature__text">Accounts</span>
    </div> -->
    <!-- <div class="feature" @click="$router.push('/e-naira')">
      <img class="feature__img" src="../../assets/images/naira.jpg" alt="naira-icon" />
      <span class="feature__text">E-Naira</span>
    </div> -->
    <!-- <div class="feature pending" @click="$router.push('')">
      <img
        class="feature__img"
        src="../../assets/images/cheques-icon.svg"
        alt="cheques-icon"
      />
      <span class="feature__text">Cheques</span>
    </div> -->
    <!-- <div class="feature" @click="$router.push('/pta')">
      <img
        class="feature__img"
        src="../../assets/images/cheques-icon.svg"
        alt="cheques-icon"
      />
      <span class="feature__text">PTA/BTA</span>
    </div> -->

    <!-- <div class="feature pending" @click="$router.push('')">
      <img
        class="feature__img"
        src="../../assets/images/more-icon.svg"
        alt="more-icon"
      />
      <span class="feature__text">More</span>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.features {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10.2rem, 1fr));
    gap: 2rem;
  }
  .feature {
    // flex: 1;
    background: #ffffff;
    box-shadow: 0px 0.4rem 1rem rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &__img {
      margin-bottom: 1rem;
    }
    &__text {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #000000;
    }
  }
}
</style>
