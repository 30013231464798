<template>
  <div>
    <div class="analysis__filter">
      <div class="analysis__amount"><Span>0.00 </Span><Span>NGN</Span></div>
      <div class="analysis__months">
        <label for="months">
          <select
            name="months"
            id="months"
            class="pending"
            v-model.lazy="month"
          >
            <option disabled value="">Select Month</option>
            <option v-for="(month, i) in months" :key="i" :value="month.value">
              {{ question.question }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <div class="analysis__wrapper">
      <div class="analysis__box">
        <div>
          <img
            class="feature__img"
            src="../../assets/images/general-icon.svg"
            alt="general-icon"
          />
          <span>General</span>
        </div>
        <p class="analysis__text">0.00 NGN</p>
      </div>
      <div class="analysis__box">
        <div>
          <img
            class="feature__img"
            src="../../assets/images/family-icon.svg"
            alt="family-icon"
          />
          <span>Family</span>
        </div>
        <p class="analysis__text">0.00 NGN</p>
      </div>
      <div class="analysis__box">
        <div>
          <img
            class="feature__img"
            src="../../assets/images/bills-icon.svg"
            alt="bills-icon"
          />
          <span>Bills</span>
        </div>
        <p class="analysis__text">0.00 NGN</p>
      </div>
      <div class="analysis__box">
        <div>
          <img
            class="feature__img"
            src="../../assets/images/dinner-icon.svg"
            alt="dinner-icon"
          />
          <span>Dinner</span>
        </div>
        <p class="analysis__text">0.00 NGN</p>
      </div>
      <div class="analysis__box">
        <div>
          <img
            class="feature__img"
            src="../../assets/images/games-icon.svg"
            alt="games-icon"
          />
          <span>Fun & Games</span>
        </div>
        <p class="analysis__text">0.00 NGN</p>
      </div>
      <div class="analysis__box">
        <div>
          <img
            class="feature__img"
            src="../../assets/images/expenses-icon.svg"
            alt="expenses-icon"
          />
          <span>Expenses</span>
        </div>
        <p class="analysis__text">0.00 NGN</p>
      </div>
      <div class="analysis__box">
        <div>
          <img
            class="feature__img"
            src="../../assets/images/shopping-icon.svg"
            alt="shopping-icon"
          />
          <span>Shopping</span>
        </div>
        <p class="analysis__text">0.00 NGN</p>
      </div>
      <div class="analysis__box">
        <div>
          <img
            class="feature__img"
            src="../../assets/images/fashion-icon.svg"
            alt="fashion-icon"
          />
          <span>Fashion</span>
        </div>
        <p class="analysis__text">0.00 NGN</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      month: "",
      months: {}
    };
  }
};
</script>

<style lang="scss" scoped>
.analysis {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16.2rem, 1fr));
    gap: 2rem;
  }
  &__box {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 1rem;

    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #000000;

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1rem;
    }
  }
  &__filter {
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  &__amount {
    display: flex;
    flex-direction: column;
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 500;
    color: #000000;
    span:nth-of-type(1) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
    span:nth-of-type(2) {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
  &__months {
    select {
      width: 100%;
      min-width: 22rem;
      background: #fbfbfb;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 0.7rem 1rem;
      outline: none;

      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.05em;
      &:focus,
      &:active {
        box-shadow: 0 0 1px 1px #ddd;
      }
      &::placeholder {
        color: #333333;
        font-weight: 500;
        font-size: 1.6rem;
      }
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: url("~@/assets/images/down-arrow.svg"), #fbfbfb;
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.65em auto, 100%;
      &:empty {
        display: none;
      }
    }
  }
  // 900px
  @media only screen and (max-width: 56.25em) {
    &__filter {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2.6rem;
    }
  }
}
</style>
