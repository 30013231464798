<template>
  <div class="user-accounts__actions">
    <router-link to="/dashboard/history" class="action__item">
      <a class="action__link">
        <div class="action__left">
          <img src="../../assets/images/history-icon.svg" alt="history-icon" />
          <span class="action__text">Transaction History</span>
        </div>
        <img
          class="action__right"
          src="../../assets/images/right-arrow.svg"
          alt="right-arrow-icon"
        />
      </a>
    </router-link>
    <router-link to="/scheduled-transactions" class="action__item">
      <a class="action__link">
        <div class="action__left">
          <img src="../../assets/images/schedule-icon.svg" alt="schedule-icon" />
          <span class="action__text">Scheduled Transactions</span>
        </div>
        <img
          class="action__right"
          src="../../assets/images/right-arrow.svg"
          alt="right-arrow-icon"
        />
      </a>
    </router-link>
    <!-- <router-link to="" class="action__item pending">
      <a class="action__link">
        <div class="action__left">
          <img src="../../assets/images/pending-icon.svg" alt="pending-icon" />
          <span class="action__text">Pending Transactions</span>
        </div>
        <img
          class="action__right"
          src="../../assets/images/right-arrow.svg"
          alt="right-arrow-icon"
        />
      </a>
    </router-link> -->
    <router-link to="/dashboard/statements" class="action__item">
      <a class="action__link">
        <div class="action__left">
          <img src="../../assets/images/statements-icon.svg" alt="statements-icon" />
          <span class="action__text">Statements</span>
        </div>
        <img
          class="action__right"
          src="../../assets/images/right-arrow.svg"
          alt="right-arrow-icon"
        />
      </a>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.user-accounts {
  &__actions {
    padding: 2rem;
    background: #fcfcfc;
    border: 1px solid #f2f2f2;
    border-radius: 0.5rem;
  }
  .action {
    &__item {
      text-decoration: none;
    }
    &__link {
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 0.5rem;
      padding: 0.7rem 1.1rem;
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      margin-bottom: 1rem;
    }
    &__left {
      display: flex;
      align-items: center;
    }
    &__text {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #000000;
      margin-left: 0.5rem;
    }
  }
}
</style>
