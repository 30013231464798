<template>
  <section class="greetings">
    <p>
      {{ timeOfDay() }}, {{ user.profileInfo.fullname.split(" ")[0] }}, What would you
      like to do today?
    </p>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      showFromModal: false,
      sharingModal: true,
      nuban: "",
    };
  },
  computed: mapState(["user"]),
  methods: {
    timeOfDay() {
      let hour = new Date().getHours();

      if (hour < 12) return "Good Morning";
      if (hour >= 12 && hour <= 17) return "Good Afternoon";
      if (hour >= 17 && hour <= 24) return "Good Evening";
    },
  },
};
</script>

<style lang="scss" scoped>
.greetings {
  margin-bottom: 2rem;
  p {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0.05em;
    color: #000000;
  }
}

.btn2 {
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 2.5rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
  margin-top: 0.5rem;
}
</style>
