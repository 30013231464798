<template>
  <div class="container">
    <app-greetings></app-greetings>
    <section class="main">
      <div class="features">
        <h2>Features</h2>
        <app-features></app-features>
      </div>
      <div class="user-accounts">
        <h2>Accounts</h2>
        <app-card-slider></app-card-slider>
        <app-account-actions></app-account-actions>
      </div>
      <div class="analysis">
        <h2>Spend Analysis</h2>

        <app-spend-analysis></app-spend-analysis>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Greetings from "@/components/DashboardHome/Greetings.vue";
import Features from "@/components/DashboardHome/Features.vue";
import CardsSlider from "@/components/DashboardHome/CardsSlider.vue";
import AccountActions from "@/components/DashboardHome/AccountActions.vue";
import SpendAnalysis from "@/components/DashboardHome/SpendAnalysis.vue";
export default {
  computed: mapState(["userAccounts", "user"]),
  components: {
    "app-features": Features,
    "app-greetings": Greetings,
    "app-card-slider": CardsSlider,
    "app-account-actions": AccountActions,
    "app-spend-analysis": SpendAnalysis
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_dashboardHome.scss";
</style>
