<template>
  <div>
    <!-- <input
      type="search"
      name="account-num"
      placeholder="Search Account Number"
      class="user-accounts__search"
      id="account-num"
      @input="filterAccounts"
      v-model.lazy="search"
    /> -->

    <div class="user-accounts__wrapping">
      <div
        v-for="account in userAccountsCopy"
        :key="account.NUBAN"
        class="user-accounts__slide"
        :class="{
          'dark-bg': account.ACCT_TYPE === 'Wallet',
        }"
      >
        <div class="top">
          <p class="wallet">{{ account.ACCT_TYPE }}</p>
          <p v-if="userAccountsCopy.length === 1" class="upgrade" @click="upgradeWallet">
            Upgrade
          </p>
        </div>
        <div class="middle">
          <p>{{ account.WorkingBalance | formatCurrencyThree }}</p>
          <p>{{ account.Currency_Code | formatCurrencySymbol }}</p>
        </div>
        <div class="bottom">
          <p>{{ account.AccountDescp }}</p>
          <p>{{ account.NUBAN }}</p>
        </div>
      </div>

      <img
        @click="slideLeft"
        class="slide-btns left-btn"
        src="../../assets/images/left-arrow.svg"
        alt="left-arrow-icon"
      />
      <img
        @click="slideRight"
        class="slide-btns right-btn"
        src="../../assets/images/right-arrow.svg"
        alt="right-arrow-icon"
      />
    </div>

    <div class="toggle-container">
      <input type="checkbox" id="hide" class="toggle" v-model="hideBalState" />
      <label for="hide" class="label">
        <div class="ball"></div>
      </label>
      <span v-if="hideBalState">Show Balance </span>
      <span v-else>Hide Balance</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
let utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export default {
  computed: {
    ...mapState(["userAccountsCopy", "user"]),
    hideBalState: {
      get() {
        return this.$store.state.hideBalState;
      },
      set(value) {
        this.$store.commit("setHideState", value);
      },
    },
  },
  data() {
    return {
      activeSlide: 0,
      slidesList: "",
      search: "",
      upgradeVisible: false,
    };
  },
  watch: {
    hideBalState: function (newValue, oldValue) {
      if (newValue === true) {
        this.$store.commit("setModifiedUserAccounts", true);
      } else if (oldValue === false || newValue === false) {
        this.$store.commit("setModifiedUserAccounts", false);
      }
    },
  },
  methods: {
    upgradeWallet() {
      this.$router.push("/account-opening");
    },
    setActiveSlide(slides) {
      slides.forEach((slide) => slide.classList.remove("active"));
      slides[this.activeSlide].classList.add("active");
    },
    slideLeft() {
      this.activeSlide--;

      if (this.activeSlide < 0) this.activeSlide = this.slidesList.length - 1;
      this.setActiveSlide(this.slidesList);
    },
    slideRight() {
      this.activeSlide++;

      if (this.activeSlide > this.slidesList.length - 1) this.activeSlide = 0;
      this.setActiveSlide(this.slidesList);
    },
    filterAccounts(e) {
      e.preventDefault();
      const query = e.target.value;
      if (query.length >= 10) {
        let foundAcc = this.userAccountsCopy.findIndex((account) =>
          account.NUBAN.toLowerCase().includes(query.toLowerCase())
        );

        if (foundAcc !== -1) {
          this.activeSlide = foundAcc;
          this.setActiveSlide(this.slidesList);
        } else {
          this.$message({
            showClose: true,
            message: `Account not found`,
            type: "warning",
            duration: 5000,
          });
        }
      }
    },
  },
  mounted() {
    const slides = document.querySelectorAll(".user-accounts__slide");
    this.slidesList = slides;
    this.setActiveSlide(slides);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.upgrade {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 800;
  font-size: 1.6rem;
  text-align: center;
  background: #db353a;
  padding: 0.5rem;
  border-radius: 0.3rem;
}
.upgrade:hover {
  cursor: pointer;
}
.wallet {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #ffffff;
  padding-top: 0.6rem;
}
.user-accounts {
  &__search {
    position: relative;
    width: 100%;
    background: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 0.7rem 2rem;
    outline: none;

    font-family: Red Hat Display;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0.05em;
    &:focus,
    &:active {
      box-shadow: 0 0 1px rgb(82, 52, 52);
    }
    &::placeholder {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #c4c4c4;
    }
  }
  &__wrapping {
    position: relative;
    overflow: hidden;
    margin: 3.2rem 0 2rem;
    min-height: 12.2rem;
    display: flex;
    justify-content: center;
    .slide-btns {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
    }
    .left-btn {
      left: 0;
    }
    .right-btn {
      right: 0;
    }
  }
  &__slide {
    position: absolute;
    transition: 0.4s ease;
    opacity: 0;
    width: 80%;
    min-width: 18rem;
    height: 100%;
    padding: 1.3rem;

    background: url("~@/assets/images/rec-one.png"),
      linear-gradient(0deg, #db353a, #db353a), #000000;
    background-position: top;
    background-size: 150%;
    background-repeat: no-repeat;
    border: 1px solid #bdbdbd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    &.active {
      opacity: 1;
    }
    &.dark-bg {
      background: url("~@/assets/images/rec-two.png"),
        linear-gradient(0deg, #db353a, #db353a), #000000;
      background-position: top;
      background-size: 150%;
      background-repeat: no-repeat;
      border: 1px solid #bdbdbd;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      border-radius: 1rem;
    }

    div {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      gap: 1rem;
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
      & > * {
        flex: 0 1 1;
      }
    }
    p {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #ffffff;
    }
    .top {
    }
    .middle {
      p:nth-of-type(2) {
        font-size: 1.6rem;
      }
    }
    .bottom {
    }
  }
}

.toggle-container {
  display: flex;
  align-items: center;
  margin: 2rem 0;
  // width: 20rem;

  .toggle {
    visibility: hidden;
  }

  .label {
    width: 6rem;
    height: 3rem;
    background: #fafafa;
    border-radius: 5rem;
    display: inline-block;
    cursor: pointer;
    margin: 0 1.5rem 0;
    position: relative;
    border: 0.1px solid #db353a;
  }

  .ball {
    background: #db353a;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideOff 0.3s linear forwards;
  }

  span {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    text-align: center;
    color: #000000;
  }

  .toggle:checked + .label {
    background-color: #e0e0e0;
  }

  .toggle:checked + .label .ball {
    animation: slideOn 0.3s linear forwards;
  }

  @keyframes slideOn {
    0% {
      transform: translateX(0) scale(1);
    }
    50% {
      transform: translateX(2rem) scale(1.2);
    }
    100% {
      transform: translateX(3rem) scale(1);
    }
  }

  @keyframes slideOff {
    0% {
      transform: translateX(3rem) scale(1);
    }
    50% {
      transform: translateX(2rem) scale(1.2);
    }
    100% {
      transform: translateX(0) scale(1);
    }
  }
}
</style>
